import React from 'react'
import Layout from '../../Layout/Layout'

const Articles = () => {
  return (
    <div>
        <Layout >
             <div className='w-full h-screen'>
             Articles
             </div>
        </Layout> 
    </div>
  )
}

export default Articles
