import React from 'react'
import Layout from '../../Layout/Layout'


const Resume = () => {
  return (
   <Layout>
      <div className='w-full flex flex-col'>
      Resume
      </div>
   </Layout>
  )
}

export default Resume
